import React, { useEffect } from "react";
import { useState } from "react";
import { useInterval } from "./components/useInterval";
import Logo from "./assets/images/assets_logo.png";

import Illu1 from "./assets/images/assets_illu1_remake.png";
import Illu2 from "./assets/images/assets_illu2-remake_2.png";
import Illu3 from "./assets/images/assets_illu3.png";
import TopRightArrow from "./assets/images/assets_2_arrow-top-r.png";
import Piano from "./assets/images/assets_keyboard.png";
import Sheep from "./assets/images/assets_sheep.png";
import Eye from "./assets/images/assets_eye.png";
import Mouth from "./assets/images/assets_mouth.png";
import Top from "./assets/images/assets_arrow.png";
import Player from "./components/Player";

import "./App.css";

const App = () => {
  const [songInfo, setSongInfo] = useState("");

  useEffect(() => {
    fetchSongInfo();
  }, []);

  useInterval(() => {
    fetchSongInfo();
  }, 10000);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchSongInfo = async () => {
    const options = {
      method: "GET",
      headers: new Headers({ "Content-Type": "text/plain;charset=utf-8" }),
    };
    const result = await fetch(
      "https://gofm-jimj.vercel.app/currentsong",
      options
    );
    const data = await result.text();
    console.log(data);
    setSongInfo(data);
  };

  return (
    <div className="App site-background-color">
      <header className="App-header">
        <div className="logo">
          <a href="/" className="logo" title="Jazz FM Romania">
            <img
              className="img-fluid w-100"
              src={Logo}
              alt="Jazz FM Romania"
              title="Jazz FM Romania"
            />
          </a>
        </div>
        <Player url="https://live.gofm.ro:2000/stream/goFMro" />
        <div className="meta-info">
          <div className="label">Now playing</div>
          <div id="artist">{songInfo?.split("-")[0]?.trim()}</div>
          <div id="song">{songInfo?.split("-")[1]?.trim()}</div>
        </div>
      </header>
      <div className="illu1-bk">
        <img src={Illu1} alt="" />
      </div>
      <div className="how-to yellow-bg">
        <div className="row step">
          <div className="col-12 col-md-8">
            <div className="big-title">
              <h2>
                NE
                <br />
                ASCULTI
                <br />
                AICI
              </h2>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="bullet-big d-none d-md-block"></div>
            <div className="schedule mt_19">
              <h3 className="f_11">
                <span className="no">1 </span>FM
              </h3>
              <div className="table-schedule">
                <div className="row-schedule">
                  <div className="name">SIBIU (continuăm)</div>
                  <div className="right text-uppercase">90,4</div>
                </div>

                <div className="row-schedule">
                  <div className="name">CLUJ NAPOCA</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">TIMISOARA</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">BRASOV</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">IASI</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">HUNEDOARA</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">ARAD</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">BIHOR</div>
                  <div className="right text-uppercase">#</div>
                </div>
                <div className="row-schedule">
                  <div className="name">(DB) MÜNCHEN</div>
                  <div className="right text-uppercase">DAB</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-2040">
          <div className="col-12 col-md-5 col-lg-4">
            <div className="schedule">
              <h3 className="f_11">
                <span className="no">2 </span>Online
              </h3>
              <div className="table-schedule">
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="https://tunein.com/radio/goFM-1032-s140487"
                      target="_blank"
                    >
                      TuneIn
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://tunein.com/radio/goFM-1032-s140487">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="http://streema.com/radios/play/GoFm_103.2_FM"
                      target="_blank"
                    >
                      Streema
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="http://streema.com/radios/play/GoFm_103.2_FM">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="https://onlineradiobox.com/ro/gofm/?cs=ro.focus"
                      target="_blank"
                    >
                      OnlineRadioBox
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://onlineradiobox.com/ro/gofm/?cs=ro.focus">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="https://mytuner-radio.com/radio/gofmro-460815/"
                      target="_blank"
                    >
                      myTuner
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://mytuner-radio.com/radio/gofmro-460815/">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="https://repla.io/radio/gofm-90-4-romania-49932.html"
                      target="_blank"
                    >
                      Replaio
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://repla.io/radio/gofm-90-4-romania-49932.html">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="https://radio.garden/listen/go-fm-ro/c1dxur2L"
                      target="_blank"
                    >
                      Radio Garden
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://radio.garden/listen/go-fm-ro/c1dxur2L">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a
                      href="http://www.romaniaradio.ro/radio/gofm-romania.shtml"
                      target="_blank"
                    >
                      RomaniaRadio
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="http://www.romaniaradio.ro/radio/gofm-romania.shtml">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
                <div className="row-schedule">
                  <div className="name">
                    <a href="https://tvron.net/radio/go-fm/" target="_blank">
                      TVRon
                    </a>
                  </div>
                  <div className="right arr">
                    <a href="https://tvron.net/radio/go-fm/">
                      <img src={TopRightArrow} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ilu purple-bk">
          <img className="ilu2" src={Illu2} alt="" />
        </div>
        <div className="marquee-ash">
          <hr className="bk-black" />
          <p>
            goFM ROMANIA goFM ROMANIA goFM ROMANIA goFM ROMANIA goFM ROMANIA
            goFM ROMANIA
          </p>
          <hr className="bk-black" />
        </div>
        <div className="marquee-ash-right">
          <p>
            SIBIU ONLINE CLUJ ONLINE ARGEȘ ONLINE SIBIU ONLINE CLUJ ONLINE
            MÜNCHEN ONLINE
          </p>
          <hr className="bk-black" />
        </div>
        <div className="marquee-ash">
          <p>
            SIBIU ONLINE CLUJ ONLINE MÜNCHEN ONLINE SIBIU ONLINE CLUJ ONLINE
            ARGEȘ ONLINE
          </p>
          <hr className="bk-black" />
        </div>
        <div className="yellow-bg center-align">
          <div className="col-md-8 offset-md-2">
            <img className="img-fluid w-100 illu-piano" src={Piano} alt="" />
          </div>
        </div>
        <div className="jazz-in-ro blue-bk">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 offset-md-1">
                <div className="wrapper-sticky">
                  <img className="img-fluid sheep" src={Sheep} alt="" />
                </div>
              </div>
              <div className="col-md-6 offset-md-1">
                <h2 className="f_11">
                  ECHIPA
                  <br />
                </h2>
                <div className="schedule">
                  <div className="table-schedule">
                    <div className="row-schedule">
                      <div className="name">Laurentiu Sever LUP</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Horatiu WELTMANN</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Cosmina DRAGUS</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Florin OTROCOL</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Daniela SOMMER</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Dana DABA</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Mark1ONE</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Marius BORCOMAN</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Hora DANCU</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">EMY</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">Calin OPRIS</div>
                      <div className="right text-uppercase"></div>
                    </div>
                    <div className="row-schedule">
                      <div className="name">GoKu</div>
                      <div className="right text-uppercase"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ilu purple-bk">
          <div className="col-12">
            <img src={Illu3} alt="" />
          </div>
        </div>
        <div className="yellow-bg container-fluid contact">
          <div className="row pt-48 yellow-bg">
            <div className="big-title col-md-6">
              <div className="wrapper-stick" id="contact">
                <h2>
                  go:
                  <br />
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bullet-big d-none d-md-block"></div>
              <div className="schedule mt_26">
                <div className="table-schedule">
                  <div className="row-schedule">
                    <p>
                      goFM România:
                      <br />
                      Studio:
                      <br />
                      Sibiu, Str. Nicolae Titulescu nr. 2
                      <br />
                      Tel.: 0736.044.055
                      <br />
                    </p>
                  </div>
                  <div className="row-schedule">
                    <p>
                      goFM Germania
                      <br />
                      Studio:
                      <br />
                      82205 Gilching (München) Bruckerstrasse 44
                      <br />
                      Tel.: +4915251357595 ​
                    </p>
                  </div>
                  <div className="row-schedule">
                    <div className="name">redactie:</div>
                    <div className="right">
                      <a href="mailto:romania@gofm.ro">romania@gofm.ro</a>
                    </div>
                  </div>
                  <div className="row-schedule">
                    <div className="name">trimite piese:</div>
                    <div className="right">
                      <a href="mailto:music@gofm.ro">music@gofm.ro</a>
                    </div>
                  </div>
                  <div className="row-schedule flex-base">
                    <div className="name f_11 facebook">
                      <a
                        href="https://www.facebook.com/gofm.ro"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </div>
                    <div className="right">
                      <a
                        href="https://www.facebook.com/gofm.ro"
                        target="_blank"
                      >
                        <img className="w50" src={TopRightArrow} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="row-schedule">
                    <img className="img-fluid eye" src={Eye} alt="" />
                  </div>
                  <div className="row-schedule">
                    <a className="font_4_120" href="terms.html">
                      Terms
                    </a>
                  </div>
                  <div className="row-schedule">
                    <a className="font_4_120" href="privacy.html">
                      Privacy
                    </a>
                  </div>
                  <div className="row-schedule">
                    <p>©2023 - goFM®.ro sustinut de goNEWS GROUP RO & DE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container-fluid">
          <div className="col-sm-6 purple-bk box-footer">
            <div className="col-md-10">
              <img className="img-fluid" src={Mouth} alt="" />
            </div>
          </div>
          <div className="col-sm-6 red-bk box-footer">
            <div className="gotop" id="topbtn">
              <button className="scroll-to-top" onClick={scrollToTop}>
                <img className="img-fluid" src={Top} alt="" />
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
